import { Component, OnInit, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';

import { Platform, NavController, ToastController, AlertController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AuthService, AlertService, GlobalService, Users } from 'src/app/services';
import { HTTP } from '@ionic-native/http/ngx';

import { environment } from 'src/environments/environment';

import {
	Capacitor,
	Plugins,
	PushNotification,
	PushNotificationToken,
	PushNotificationActionPerformed,
	StatusBarStyle,
} from '@capacitor/core';

const { Browser, Device, Network, PushNotifications, StatusBar, SplashScreen } = Plugins;

import { TranslateService } from '@ngx-translate/core';



@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

	public appversion: any;
	public isAndroid: any;
	public deviceVersionLookup: any;
	public shouldUpdate;
	public isUpdating = 0;

	public _MESSAGE_INACTIVE: string;
	public _MESSAGE_UPDATE: string;
	public _MESSAGE_UPDATE_MESSAGE: string;
	public _CLOSE: string;
	public _UPDATE: string;

	public is_native: boolean;

	constructor(
		private platform: Platform,
		// private splashScreen: SplashScreen,
		// private statusBar: StatusBar,
		private globalvar: GlobalService,
		private authService: AuthService,
		private alertService: AlertService,
		private navCtrl: NavController,
		private router: Router,
		private http: HTTP,
		private zone: NgZone,
		private users: Users,
		public toastController: ToastController,
		public alertCtrl: AlertController,
		public translate: TranslateService,


	) {
		translate.setDefaultLang('en');

		this.initializeApp();
	}

	async initializeApp() {
		//   let language = this.translate.getBrowserLang();
		this.translate.setDefaultLang('en');
		this.getTranslations();
		await this.getDevice();


		this.platform.ready().then(() => {
			// this.splashScreen.hide();
			this.setStatusBarStyle();

			// if(Capacitor.getPlatform() == 'web'){
			// 	this.is_native == false;
			// }
			// else{
				this.is_native == true;
			// }

			this.globalvar.setToken();

			
			if ((this.globalvar.device.manufacturer).toLowerCase().includes('zebra')) {
				this.platform.resume.subscribe(() => {
					this.initScanner();
		
				});
		
				this.platform.pause.subscribe(async () => {
					(window as any).plugins.intentShim.unregisterBroadcastReceiver();
		
				});


				this.initScanner(); //only init the scanner on zebra devices
			}


			//dont do for web
			this.globalvar.setInactivityCounter(this.authService);
			



			this.getCurrentNetworkStatus();
			let handler = Network.addListener('networkStatusChange', (status) => {
				console.log("Network status changed", status);
				this.zone.run(() => {
					this.globalvar.network = status;
				});
			});

			// this.statusBar.styleDefault();

			this.authService.getToken();
			this.isAndroid = this.platform.is('android');


			this.platform.backButton.subscribe(() => {
				console.log(window.history.length);
				if (this.router.url != '/login' && this.router.url != '/home') {
					window.history.back();
				} else {
					navigator['app'].exitApp();
				}
			});

			this.checkLoggedin();

			this.platform.resume.subscribe(() => {
				console.log('resumed');
				this.checkLoggedin();

				this.globalvar.setInactivityCounter(this.authService);
			});
			/*
					this.platform.pause.subscribe(async () => {
						console.log('paused'); 
						this.checkLoggedin();
				
					});
			*/

		});

		let handler = Network.addListener('networkStatusChange', (status) => {
			console.log("Network status changed", status);
		});

	}

	setStatusBarStyle(){
		StatusBar.setStyle({style: StatusBarStyle.Dark});
		StatusBar.setBackgroundColor({color:"#000000"})
		SplashScreen.hide();


	}

	async getDevice(){
		this.globalvar.device = await Device.getInfo();
	}

	getTranslations() {

		this.translate.get('_MESSAGE_INACTIVE').subscribe((text: string) => {
			this._MESSAGE_INACTIVE = text;
		});
		this.translate.get('_CLOSE').subscribe((text: string) => {
			this._CLOSE = text;
		});
		this.translate.get('_MESSAGE_UPDATE').subscribe((text: string) => {
			this._MESSAGE_UPDATE = text;
		});
		this.translate.get('_MESSAGE_UPDATE_MESSAGE').subscribe((text: string) => {
			this._MESSAGE_UPDATE_MESSAGE = text;
		});
		this.translate.get('_UPDATE').subscribe((text: string) => {
			this._UPDATE = text;
			// this.checkForUpdates();
		});

	}

	async getCurrentNetworkStatus() {
		let state = await Network.getStatus();
		this.zone.run(() => {
			this.globalvar.network = state;
		});
		console.log(this.globalvar.network);
	}

	ngOnInit() {

		// Request permission to use push notifications
		// iOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting

		if (Capacitor.platform != 'web') {

			PushNotifications.requestPermission().then(result => {
				if (result.granted) {
					// Register with Apple / Google to receive push via APNS/FCM
					PushNotifications.register();
				} else {
					// Show some error
					console.log("some error occured");
				}
			});

			// On success, we should be able to receive notifications
			PushNotifications.addListener('registration',
				(token: PushNotificationToken) => {
					console.log('Push registration success, token: ' + token.value);
					this.globalvar.pushkey = token.value;
				}
			);

			// Some issue with our setup and push will not work
			PushNotifications.addListener('registrationError',
				(error: any) => {
					console.log('Error on registration: ' + JSON.stringify(error));
				}
			);

			// Show us the notification payload if the app is open on our device
			PushNotifications.addListener('pushNotificationReceived',
				(notification: PushNotification) => {
					console.log('Push received: ' + JSON.stringify(notification));
				}
			);

			// Method called when tapping on a notification
			PushNotifications.addListener('pushNotificationActionPerformed',
				(notification: PushNotificationActionPerformed) => {
					console.log('Push action performed: ' + JSON.stringify(notification));
				}
			);

		}


	}

	checkLoggedin() {
		this.checkForUpdates();

		if (this.authService.isLoggedIn && this.authService.token.access_token) {
			this.authService.user().subscribe(
				user => {
					this.zone.run(() => {
						this.globalvar.myuser = user;
					});
					if (!this.globalvar.myuser.is_active || this.globalvar.myuser.is_active == '0') {
						this.alertService.presentToast(this._MESSAGE_INACTIVE, 'success');
						this.logout();
					} else {
						this.globalvar.setObject('user', user)
							.then(
								() => {
									this.users.storePushkey();
									console.log('Stored item!');
								}
							);
					}
				},
				error => {

					if (error.status == 401 || error.status == 404) {
						this.authService.isLoggedIn = false;
						this.authService['token'] = { 'access_token': '' };
						this.navCtrl.navigateRoot('/login');
					}



				}
			);
		} else {
			this.authService.isLoggedIn = false;
			this.authService['token'] = { 'access_token': '' };
			this.navCtrl.navigateRoot('/login');
		}
	}


	openPage(page) {
		this.navCtrl.navigateRoot(page);
	}


	logout() {

		this.authService.logout().subscribe(
			data => {
				this.globalvar.clearStorage();
				this.authService.isLoggedIn = false;
				this.navCtrl.navigateRoot('/login');
				//         this.storePushkey('');  

			},
			error => {
				console.log(error);
				this.globalvar.clearStorage();
				this.authService.isLoggedIn = false;
				this.navCtrl.navigateRoot('/login');
				// 		this.storePushkey(''); 
			}
		);
	}


	async presentToastWithOptions(data) {
		const toast = await this.toastController.create({
			header: data.title,
			message: data.message,
			//       message: data.body,
			duration: 5000,
			position: 'bottom',
			buttons: [
				{
					text: this._CLOSE,
					role: 'cancel',
					handler: () => {
						console.log('Cancel clicked');
					}
				}
			]
		});
		toast.present();
	}

	async checkForUpdates() {
		const device = await Device.getInfo();
		this.appversion = device.appVersion;

		console.log(device);

		this.http.get(`${environment.UPDATE_URL}`, {}, {}).then(
			res => {
				var data = JSON.parse(res.data);

				if (this.isAndroid) {
					this.deviceVersionLookup = data.androidVersion;
				} else {
					this.deviceVersionLookup = data.iosVersion;
				}

				this.shouldUpdate = this.globalvar.versionComparision(this.deviceVersionLookup, this.appversion);
				if (this.shouldUpdate == 1) {
					this.versionPopup();
				}
			})
			.catch(
				error => {
					console.log(error);

				});


	}

	async versionPopup() {
		this.isUpdating = 1;
		const alert = await this.alertCtrl.create({
			header: this._MESSAGE_UPDATE,
			message: this._MESSAGE_UPDATE_MESSAGE,
			buttons: [
				{
					text: this._CLOSE,
					role: 'cancel',
					handler: data => {
						console.log('Cancel clicked');
					}
				},
				{
					text: this._UPDATE,
					role: 'save',
					handler: data => {
						if (this.isAndroid) {
							Browser.open({ url: environment.UPDATE_PlayStore });
						} else {
							Browser.open({ url: environment.UPDATE_AppStore });
						}
					}
				}
			]
		});
		await alert.present();
	}











	private scans = [];
	private scanners = [{ "SCANNER_NAME": "Please Wait...", "SCANNER_INDEX": 0, "SCANNER_CONNECTION_STATE": true }];
	private selectedScanner = "Please Select...";
	private selectedScannerId = -1;
	private ean8Decoder = true;   //  Model for decoder
	private ean13Decoder = true;  //  Model for decoder
	private code39Decoder = true; //  Model for decoder
	private code128Decoder = true;//  Model for decoder
	private dataWedgeVersion = "Pre 6.3. Please create & configure profile manually.  See the ReadMe for more details.";
	private availableScannersText = "Requires Datawedge 6.3+"
	private activeProfileText = "Requires Datawedge 6.3+";
	private commandResultText: String = "Messages from DataWedge will go here";
	private uiHideDecoders = true;
	private uiDatawedgeVersionAttention = true;
	private uiHideSelectScanner = true;
	private uiHideShowAvailableScanners = false;
	private uiHideCommandMessages = true;
	private uiHideFloatingActionButton = true;
	private requestResultCodes = "false";

	initScanner() {
		console.log('creating scanner at ' + new Date());

		var that = this;
		(window as any).plugins.intentShim.registerBroadcastReceiver({

			filterActions: [
				'nl.appcomm.imatuve.ACTION',
				'com.symbol.datawedge.api.RESULT_ACTION'
			],
			filterCategories: [
				'android.intent.category.DEFAULT'
			]
		},
			function (intent: any) {

				//  Check manufacturer.  Exit if this app is not running on a Zebra device
				//console.log("Device manufacturer is: " + this.device.manufacturer);

				//  Broadcast received
				// console.log(JSON.stringify(intent.extras));
				//  Broadcast received
				//  console.log(intent.extras);
				//  Emit a separate event for the result associated with this scan.  This will only be present in response to
				//  API calls which included a SEND_RESULT extra
				if (intent.extras.hasOwnProperty('RESULT_INFO')) {
					let commandResult = intent.extras.RESULT + " (" +
						intent.extras.COMMAND.substring(intent.extras.COMMAND.lastIndexOf('.') + 1, intent.extras.COMMAND.length) + ")";  // + JSON.stringify(intent.extras.RESULT_INFO);
					that.publishCommandResult(commandResult.toLowerCase());
				}

				if (intent.extras.hasOwnProperty('com.symbol.datawedge.api.RESULT_GET_VERSION_INFO')) {
					//  The version has been returned (DW 6.3 or higher).  Includes the DW version along with other subsystem versions e.g MX  
					var versionInfo = intent.extras['com.symbol.datawedge.api.RESULT_GET_VERSION_INFO'];
					// console.log('Version Info: ' + JSON.stringify(versionInfo));
					let datawedgeVersion = versionInfo['DATAWEDGE'];
					// console.log("Datawedge version: " + datawedgeVersion);

					//  Fire events sequentially so the application can gracefully degrade the functionality available on earlier DW versions
					if (datawedgeVersion >= "6.3") {
						that.dw63ApisAvailable();
					}
					if (datawedgeVersion >= "6.4") {
						// 	            alert("dw64ApisAvailable");
						that.dw64ApisAvailable();
					}
					if (datawedgeVersion >= "6.5") {
						// 	            alert("dw65ApisAvailable");
						that.dw64ApisAvailable();
						that.dw65ApisAvailable();

					}
				}
				else if (intent.extras.hasOwnProperty('com.symbol.datawedge.api.RESULT_ENUMERATE_SCANNERS')) {
					//  Return from our request to enumerate the available scanners
					let enumeratedScanners = intent.extras['com.symbol.datawedge.api.RESULT_ENUMERATE_SCANNERS'];
					that.hasEnumeratedScanners(enumeratedScanners);
				}
				else if (intent.extras.hasOwnProperty('com.symbol.datawedge.api.RESULT_GET_ACTIVE_PROFILE')) {
					//  Return from our request to obtain the active profile
					let activeProfile = intent.extras['com.symbol.datawedge.api.RESULT_GET_ACTIVE_PROFILE'];
					that.getActiveProfile(activeProfile);
				}
				else if (!intent.extras.hasOwnProperty('RESULT_INFO')) {
					//  A barcode has been scanned
					that.globalvar.scanIsExecuted(intent, new Date().toLocaleTimeString());
				}
			});

		this.sendCommand("com.symbol.datawedge.api.GET_VERSION_INFO", "");




	}

	//
	//
	//
	// Related to the scanner

	ngOnDestroy() {
		(window as any).plugins.intentShim.unregisterBroadcastReceiver();
	}

	registerBroadcastReciever() {

	}

	publishCommandResult(commandResult: String) {
		// this.commandResultText = commandResult;
		// this.changeDetectorRef.detectChanges();
		// 	  alert(commandResult);

	}
	dw63ApisAvailable() {
		// console.log("DataWedge 6.3 APIs are available");
		//  We are able to create the profile under 6.3.  If no further version events are received, notify the user
		//  they will need to create the profile manually
		this.sendCommand("com.symbol.datawedge.api.CREATE_PROFILE", "ZebraIonicDemo");
		this.dataWedgeVersion = "6.3.  Please configure profile manually.  See the ReadMe for more details.";

		//  Although we created the profile we can only configure it with DW 6.4.
		this.sendCommand("com.symbol.datawedge.api.GET_ACTIVE_PROFILE", "");

		//  Enumerate the available scanners on the device
		this.sendCommand("com.symbol.datawedge.api.ENUMERATE_SCANNERS", "");

	}

	getActiveProfile(activeProfile: any) {

	}

	dw64ApisAvailable() {

		// console.log("DataWedge 6.4 APIs are available");

		//  Documentation states the ability to set a profile config is only available from DW 6.4.
		//  For our purposes, this includes setting the decoders and configuring the associated app / output params of the profile.
		this.dataWedgeVersion = "6.4";
		this.uiDatawedgeVersionAttention = false;
		this.uiHideDecoders = !true;

		//  Configure the created profile (associated app and keyboard plugin)
		let profileConfig = {
			"PROFILE_NAME": "ZebraIonicDemo",
			"PROFILE_ENABLED": "true",
			"CONFIG_MODE": "UPDATE",
			"PLUGIN_CONFIG": {
				"PLUGIN_NAME": "BARCODE",
				"RESET_CONFIG": "true",
				"PARAM_LIST": {}
			},
			"APP_LIST": [{
				"PACKAGE_NAME": "nl.appcomm.imatuve",
				"ACTIVITY_LIST": ["*"]
			}]
		};
		this.sendCommand("com.symbol.datawedge.api.SET_CONFIG", profileConfig);

		//  Configure the created profile (intent plugin)
		let profileConfig2 = {
			"PROFILE_NAME": "ZebraIonicDemo",
			"PROFILE_ENABLED": "true",
			"CONFIG_MODE": "UPDATE",
			"PLUGIN_CONFIG": {
				"PLUGIN_NAME": "INTENT",
				"RESET_CONFIG": "true",
				"PARAM_LIST": {
					"intent_output_enabled": "true",
					"intent_action": "nl.appcomm.imatuve.ACTION",
					"intent_delivery": "2"
				}
			}
		};
		this.sendCommand("com.symbol.datawedge.api.SET_CONFIG", profileConfig2);

		//  Give some time for the profile to settle then query its value
		// setTimeout(function () {
		//   // home.this.sendCommand("com.symbol.datawedge.api.GET_ACTIVE_PROFILE", "");
		// }, 1000);

		// this.changeDetectorRef.detectChanges();
	}

	dw65ApisAvailable() {

	}

	hasEnumeratedScanners(enumeratedScanners: any) {
		// throw new Error("Method not implemented.");
	}






	sendCommand(extraName: string, extraValue: any) {
		// console.log("Sending Command: " + extraName + ", " + JSON.stringify(extraValue));
		(window as any).plugins.intentShim.sendBroadcast({
			action: 'com.symbol.datawedge.api.ACTION',
			extras: {
				[extraName]: extraValue,
				"SEND_RESULT": this.requestResultCodes
			}
		},
			function (success: any) {
				// console.log(extraName + " Success");
				// console.log(success)
			},  //  Success in sending the intent, not success of DW to process the intent.
			function (error: any) {
				// console.log(extraName + " error");
				console.log(error);
			}   //  Failure in sending the intent, not failure of DW to process the intent.
		);
	}


}

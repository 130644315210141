import { Injectable, isDevMode } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { User } from 'src/app/models/user';
// import { AuthService } from 'src/app/services';
import { Plugins } from '@capacitor/core';

import { Idle } from 'idlejs/dist';

const { Storage } = Plugins;



@Injectable({
  providedIn: 'root'
})
export class GlobalService {

	public token;
	public demo_token = null;
	public myuser: any = {'id': null, 'avatar': '', 'name': '', 'companyname': ''};
	public mycompany	: any = {'id':'','name':''};
	public idle;

	public clearStorage(){
		this.token = null;
		this.removeItem('token');
		this.demo_token = null;
		this.myuser = {'id': null, 'avatar': '', 'name': '', 'companyname': ''};
		this.mycompany = {'id':'','name':''};
		this.setObject('user', this.myuser);
	}

	 public async setToken(){
		
			this.getObject('token').then((token) => {
				this.token = token;
				// console.log(this.globalvar.token);
			});
		
	};


	public setInactivityCounter(authService){
		if(!this.platform.is('desktop') || (this.device.manufacturer).toLowerCase().includes('zebra')){
			this.idle = new Idle()
			.whenNotInteractive()
			.within(10)//minutes of (60, 1000) to get seconds 
			.do(() => {
				authService.idle();
			})
			.start();
		}
	}

	public app_name = "imat-uve"; //used to store values with an unique key
	public pushkey : string;
	public pushEnabled : boolean;
	public pushNotificationEnabled : boolean;
	public network: any = {
		connected: true, 
		connectionType: "wifi"
	};
// 	private _isDev: boolean = ((<any>window)['IonicDevServer'] != undefined);
	public domain	: string;
	public images	: string;
	public api	 	: string;
	public login	: string;
	public firebaseConfig: any;
	public version: string;
	public officeAFK:boolean = false;
	public storage: string;
	public socket: string;
	public currentChat: string = "";
	public totalChats: number = 0;



	public verification_email 		= '[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}';
	public verification_password 	= '^.{3,}$';
	public verification_name 		= '[a-zA-Z ]*';
	public verification_phone 		= '^((\\+91-?)|0)?[0-9]{10}$';
	public verification_street 		= "[a-zA-Z0-9 \,\.\-]*";
	public verification_zipcode 	= '^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$';
	public verification_city 		= '[a-zA-Z \,\.\-]*';
	public verification_country 	= '[a-zA-Z \,\.\-]*';
	public verification_about		= '[a-zA-Z \,\.\-]*';
	public verification_remarks		= '^.{6,}$';
	public verification_notes		= '^.{0,}$';

// 	public verification_

	public device = null;
	public deploy		= '0.0.27';

	//temp function will be overriden from each multiscan/multiselect element
	public scanIsExecuted(intent: any, dateTime: String){}
	public sound_file_error = `assets/sounds/beep.mp3`;

	constructor(
		public alertCtrl: AlertController,
		public platform: Platform
		// private authService: AuthService
	) {		

	}

	public getBaseFileUrl(){

		return this.platform.is('android') ? '/android_asset/public/' : (this.platform.is('ios') ? '' : '');
		
	}
	
	
	async setObject(key, value) {
	  return await Storage.set({
	    key: this.app_name +'_'+ key,
	    value: JSON.stringify(value)
	  });
	}
	
	async getObject(key) {
	  const ret = await Storage.get({ key: this.app_name +'_'+ key });
	  return  JSON.parse(ret.value);
	}

	async removeItem(key) {
	  await Storage.remove({ key: this.app_name +'_'+ key });
	}
	
	async setItem(key, value) {
	  await Storage.set({
	    key: this.app_name +'_'+ key,
	    value: value
	  });
	}
	
	async getItem(key) {
	  const { value } = await Storage.get({ key: this.app_name +'_'+ key });
	  return value;
	}

	async clear() {
	  await Storage.clear();
	}
	
	
	versionComparision(a, b) {
	   console.log('versions:'+ a + '  ' + b);
	    if (a === b) {
	       return 0;
	    }
	
	    var a_components = a.split(".");
	    var b_components = b.split(".");
	
	    var len = Math.min(a_components.length, b_components.length);
	
	    // loop while the components are equal
	    for (var i = 0; i < len; i++) {
	        // A bigger than B
	        if (parseInt(a_components[i]) > parseInt(b_components[i])) {
	            return 1;
	        }
	
	        // B bigger than A
	        if (parseInt(a_components[i]) < parseInt(b_components[i])) {
	            return -1;
	        }
	    }
	
	    // If one's a prefix of the other, the longer one is greater.
	    if (a_components.length > b_components.length) {
	        return 1;
	    }
	
	    if (a_components.length < b_components.length) {
	        return -1;
	    }
	
	    // Otherwise they are the same.
	    return 0;
	}
  


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ShrinkingSegmentHeader } from './shrinking-segment-header/shrinking-segment-header.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { ImageShellComponent } from './image-shell/image-shell.component';
import { TextShellComponent } from './text-shell/text-shell.component';
import { AspectRatioComponent } from './aspect-ratio/aspect-ratio.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SwitchUserBarComponent } from './switch-user-bar/switch-user-bar.component';


import { MultiselectModalPage } from './multiscan/multiselect-modal/multiselect-modal.page';
import { MultiscanComponent } from './multiscan/multiscan.component';


@NgModule({
  declarations: [
  	ShrinkingSegmentHeader,
  	ShowHidePasswordComponent,
  	ImageShellComponent,
  	TextShellComponent,
  	AspectRatioComponent,
  	MultiscanComponent,
  	MultiselectModalPage,
  	SwitchUserBarComponent,

  ],
  imports: [
	  CommonModule,

  ],
  exports:[
	ShrinkingSegmentHeader,
	TranslateModule,
	ShowHidePasswordComponent,
	ImageShellComponent,
	TextShellComponent,
	AspectRatioComponent,
   FontAwesomeModule,
   MultiscanComponent,
   MultiselectModalPage,
   SwitchUserBarComponent,

  ]
})
export class ComponentsModule {}
